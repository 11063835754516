import axios from "axios";
import { emitter } from "@/events";
import router from "@/router";
export const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30000,
  transformRequest: axios.defaults.transformRequest.concat((data) => {
    emitter.emit("loading", true);

    return data;
  }),
});

http.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    if (!config.url.includes("/users/api-auth")) {
      if (token) {
        config.headers["Authorization"] = `Token ${token}`;
      }
    }
    config.headers["X-User-Role"] = role;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function(response) {
    setTimeout(() => emitter.emit("loading", false), 1000);
    return response;
  },
  function(error) {
    setTimeout(() => emitter.emit("loading", false), 1000);
    if (!error.response) {
      emitter.emit("network:error", error.message);
      emitter.emit("alert:error", error.message);
    } else if (error.response.status >= 500) {
      emitter.emit("server:error", error.response.status);
      emitter.emit("alert:error", "Erro interno");
    } else if (error.response.status == 401) {
      emitter.emit("alert:error", "Usuário não autenticado.");
      router.push({ name: "app:login" });
    }
    else if(error.response.status == 403){
      emitter.emit("alert:error", "Usuário sem privilégios.");
      router.push({ name: "app:home" });
    }
    else {
      return Promise.reject(error);
    }
  }
);

export class CRUD {
  constructor(uri) {
    this.uri = uri;
  }
  get(id) {
    return http.get(`${this.uri}/${id}/`);
  }
  post(data) {
    return http.post(`${this.uri}/`, data);
  }
  update(id, data) {
    return http.put(`${this.uri}/${id}/`, data);
  }
  list(params) {
    return http.get(`${this.uri}/`, {
      params,
    });
  }

  remove(id) {
    return http.delete(`${this.uri}/${id}`);
  }
}
